#column_right {
  .customerService {
    h2 {
      margin: 0;
    }

    ul.customerServiceList {
      padding: 0;
    }
  }
}