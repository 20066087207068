.qtip {
  @include media-breakpoint-down(lg) {
    display: none !important;
  }
}

#qtip_info_modal {
  .modal .modalContent p {
    margin-top: 0;
  }
}