$table-header-bg-color: #E8E9EA;

#column_wide {
  .statementoverview {
    width: auto;
    margin: 0;

    .oheading {
      height: auto;
      padding-top: .15rem;
      padding-bottom: .15rem;
    
      p {
        font-size: inherit;
      }
    }
  }
}