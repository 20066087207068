table {
  font-size: 1rem;
}

#column_wide {
  .borderwrap_wide {
    .tablewrap {
      padding: 0;
      width: 100%;
      background: none;
      float: none;
    }
  }
}

.tablewrap {
  padding: 1rem;

  table {
    width: 100%;
    font-size: inherit;
  }
}

.table_scroll {
  overflow-x: auto;
}

table.secondary_header {
  tr {
    th {
      background: $secondary-color;
      height: auto;
      font-size: inherit;
      padding: .25rem;
      line-height: 1.2;
      vertical-align: middle;

      &.sorting_disabled {
        // Ensure specificity
        padding: .25rem;
      }

      &:not([width]) {
        width: auto;
      }
    }
  }

  tr th:first-child,
  tr td:first-child {
    border-left: 1px solid $secondary-color;

    &.noimg {
      // Ensure specificity
      border-left: 1px solid $secondary-color;
    }
  }

  tr th:last-child,
  tr td:last-child {
    border-right: 1px solid $secondary-color;

    &.noimg {
      // Ensure specificity
      border-left: 1px solid $secondary-color;
    }
  }
}

table.calculation {
  th, td {
    font-size: 1rem;
  }
}

@mixin table-responsive-vertical-mixin ($size) {
  @include media-breakpoint-down($size) {
    table.table-responsive-vertical-#{$size} {

      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr {
        border: 1px solid darken($secondary-color, 30%);
        border-bottom: 2px solid darken($secondary-color, 40%);
        border-right: 2px solid darken($secondary-color, 40%);
        display: block;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }

        td,
        &.row1 td {
          width: 100% !important;
          border: 1px solid $secondary-color;
          display: block;
          text-align: right !important;
    
          &::before {
            content: attr(data-th);
            font-size: 1rem;
            color: #797979;
            float: left;
          }
        }
      }

    }
  }
}

@include table-responsive-vertical-mixin(sm);
@include table-responsive-vertical-mixin(md);