.payidtable {
  margin: 0;

  tr {
    th {
      span {
        padding: 0;
        border: none;
        height: auto;
      }
    }
  }
}

#pbsForm {
  .invoerform {
    br {
      display: none;
    }
  }
}


.payment_div {
  padding-top: 5px;
  
  input { 
    width: 98px;
  }
  
  .payment_info p {
    font-size: 14px;
  }
}
  
@include media-breakpoint-down(sm) {
  .payment_div {
    padding-top: 0px;

    input { 
      width: 84px;
    }
  }
  
 }
