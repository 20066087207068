.apply_for_loan_box {
  display: flex;
  justify-content: row;
  
  height: auto !important; // Overwrite inline style

  > * {
    float: none;
  }

  > a {
    flex: 0 0 150px;

    @include media-breakpoint-down(xs) {
      flex: 0 0 75px;
    }
  }
}