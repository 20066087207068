a.btn_big {
  font-size: 1rem;

  span {
    font-size: 1rem;
  }
}

div.invoerform a.btn_big span {
  font-size: 1rem;
}
.btn_small {
  font-size: 0.8rem;
  padding: 0.4rem;
  color: #fff;
  background-color: #00915a;
  border-radius: 5rem;
  display: inline-block;
  float: right;
  cursor: pointer;
  text-decoration: none;
}
