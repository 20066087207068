#container {
  padding-bottom: 2rem;
  margin-bottom: calc(-#{$footer-ul-height + $footer-border-top-width} - 2rem);
}

#footer {
  width: auto;
  max-width: 986px;
  font-size: inherit;
  padding: 0;
  height: calc(#{$footer-ul-height} + 2rem);
  border-top-width: $footer-border-top-width;

  ul {
    display: flex;
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: $footer-ul-height;

    .stip {
      margin-left: .33rem;
      margin-right: .33rem;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin: 1rem;

      li {
        margin-top: .33rem;
        margin-bottom: .33rem;

        a {
          margin: 0;
        }

        &.stip {
          display: none;
        }
      }
    }
  }
}