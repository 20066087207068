#content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  #column_left {
    float: none;
    width: auto;
    display: flex;
    flex: 0 0 25%;

    @include media-breakpoint-down(sm) {
      flex: 0 0 100%;
    }
  }

  #column_center,
  #column_wide {
    flex-grow: 1;
    float: none;
    width: auto;
    min-width: 0;
    margin-left: 1rem;
    margin-right: 1rem;
    min-height: auto;
  }

  #column_wide {
    margin-right: 0;

    @include media-breakpoint-down(md) {
      margin-right: 1rem;
    }
  }

  #column_right {
    float: none;
    width: auto;
    padding: 0;
    flex: 0 0 25%;

    .borderwrap .content {
      @include media-breakpoint-down(sm) {
        font-size: 1rem;
      }
    }

    @include media-breakpoint-down(sm) {
      margin: 1rem;
      flex: 0 0 100%;
      font-size: 1rem;
    }
  }

  #column_wide .borderwrap {
    margin-bottom: 1rem;

    .oheading {
      height: auto;

      p {
        font-size: inherit;
        margin: 0 0.5rem 0 0.5rem;
        padding: 0;
      }
    }
  }

  #column_wide .borderwrap_wide {
    float: none;

    .oheading {
      width: 100%;
      float: none;
      display: flex;
      align-items: center;
      height: auto;

      p {
        font-size: inherit;
        margin: 0 0.5rem 0 0.5rem;
        padding: 0;
      }
    }

    .content_wide {
      width: 100%;
      display: flex;
      flex-direction: column !important;
      float: none;
      background: none;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      #left,
      #right {
        float: none;
        width: auto;
        flex: 0 0 50%;
        margin: 0;
        padding: 0.5rem;

        @include media-breakpoint-down(sm) {
          flex: 0 0 100%;
        }

        p {
          font-size: inherit;
        }
      }

      .box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        float: none;
        width: 100%;

        @include media-breakpoint-down(sm) {
          flex-direction: column;
          align-items: flex-start;
        }

        &.box-vert {
          flex-direction: column;
          align-items: flex-start;
          padding: 1rem;
        }

        &.box-2-hor {
          padding: 0.5rem 1rem 0.5rem 1rem;

          > * {
            flex: 0 0 50%;
            float: none;

            @include media-breakpoint-down(sm) {
              flex: 0 0 100%;
            }
          }
        }

        .right {
          width: auto;

          @include media-breakpoint-down(sm) {
            margin-top: 0.5rem;
            width: 100%;
          }

          select {
            width: 100%;
          }
        }
      }
    }
  }
}

#container {
  width: auto;
  max-width: 986px;
}

#column_center .content.content-home {
  max-width: none;
  padding: 1px; // Align with welcome-border
}

#column_center #naLoginWelkomTekst p {
  font-size: inherit;
}

.content {
  .invoerform {
    margin: 0;

    .boxDymHeigt {
      font-size: inherit;
      padding: 0.5rem 1rem;
      line-height: normal;
      margin: 0;
      display: flex;
      align-items: center;

      @include media-breakpoint-down(xs) {
        flex-direction: column;
        align-items: flex-start;

        & > label {
          font-weight: bold;
        }

        & .right {
          width: 100%;

          input,
          select {
            width: 100%;
          }
        }
      }

      label,
      span {
        font-size: inherit;
        float: none;
        width: auto;
      }

      &[class*="boxDymHeigt_line_"] {
        position: relative;
        padding-right: 2.5rem; // For tooltips
        margin-bottom: 0.125rem;
        align-content: center;
        align-items: baseline;

        .right {
          flex-grow: 1;
        }

        a.infoinput {
          position: absolute;
          top: 0.5rem;
          right: 1rem;
          order: 1;
          align-self: flex-end;
        }
      }

      &.boxDymHeigt_line {
        justify-content: space-between;

        > * {
          float: none;
        }

        a.infoinput {
          order: 1;

          @include media-breakpoint-down(xs) {
            order: 0;
            align-self: flex-end;
          }
        }
      }

      &.boxDymHeigt_line_checkbox {
        flex-flow: row nowrap;
        justify-content: flex-start;

        & > input[type="checkbox"] {
          margin-right: map-get($spacers, 2);
        }

        & > label {
          font-weight: normal !important;
        }
      }

      &.boxDymHeigt_line_radio {
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;

        &.boxDymHeigt_line_radio_horizontal {
          align-items: baseline;
        }

        & > span {
          width: 50%;
          min-width: 50%;
          padding-right: map-get($spacers, 3);
        }

        input[type="radio"],
        label {
          width: auto;
          margin-right: map-get($spacers, 2);
          font-weight: normal;
        }

        & > .right {
          display: flex !important;
          flex-flow: column !important;

          & > .radio {
            display: flex;
            flex-flow: row nowrap;
          }

          & label {
            margin-bottom: map-get($spacers, 1);
          }
        }

        @include media-breakpoint-down(xs) {
          flex-wrap: wrap;

          & > span {
            width: 100%;
            margin-bottom: map-get($spacers, 2);
            padding-right: 0;
            font-weight: bold;
          }
        }
      }

      &.boxDymHeigt_line_select {
        flex-flow: row nowrap;

        & > label {
          width: 50% !important;
          min-width: 50%;
          padding-right: map-get($spacers, 3);
        }

        select {
          width: auto !important;
          max-width: 100% !important;
        }

        @include media-breakpoint-down(xs) {
          flex-wrap: wrap;

          & > label {
            width: 100%;
            margin-bottom: map-get($spacers, 2);
            padding-right: 0;
          }
        }
      }

      &.boxDymHeigt_line_textinput {
        flex-flow: row nowrap;
        justify-content: flex-start;

        & > label {
          width: 50% !important;
          min-width: 50%;
          padding-right: map-get($spacers, 3);
        }

        @include media-breakpoint-down(xs) {
          flex-direction: column;

          & > label {
            width: 100% !important;
            padding-right: map-get($spacers, 5);
            margin-bottom: map-get($spacers, 1);
          }
        }
      }

      &.boxDymHeigt_line_keyvalue {
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;

        & > label {
          width: 50% !important;
          min-width: 50%;
          padding-right: map-get($spacers, 3);
        }

        @include media-breakpoint-down(xs) {
          flex-direction: column;

          & > label {
            width: 100% !important;
            padding-right: map-get($spacers, 5);
            margin-bottom: map-get($spacers, 1);
          }
        }
      }
    }

    br[style="clear:both;"] {
      display: none;
    }
  }
}

.alignField {
  @include media-breakpoint-down(xs) {
    display: flex;
    flex-direction: column;

    input,
    select {
      width: 100%;
    }
  }
}

.flex-grow-1 {
  flex-grow: 1;
}
