#content {
  #transactionsForm {
    .content_with_search {
      &.content_wide {
        flex-direction: column;
      }

      .search {
        float: none;
        display: flex;
        margin: 0.5rem;
        align-self: flex-end;

        @include media-breakpoint-down(sm) {
          display: none;
          order: 0;
        }

        + p.melding {
          font-size: inherit;
          padding: 0;
          margin: .5rem;
        }
      }
    }
  }
}

#transactionsTable_wrapper {
  padding: .5rem;
  overflow-x: auto;

  #transactionsTable {
    font-size: 1rem;
    margin-bottom: 0;

    td.transactiondate, td.bookingdate {
      width: auto;
      white-space: nowrap;
    }
  }
}

.transactionsTable_after {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: .5rem;
  padding-right: .5rem;

  .dataTables_info, .dataTables_paginate, .totalBox {
    float: none;
    font-size: inherit;
    width: auto;
    display: flex;
  }
}

#totalBox {
  .positie {
    font-size: inherit;
  }
}