html {
  box-sizing: border-box;
}

html, body {
  font-size: initial;
}

form {
  font-size: inherit;
}

*, *:before, *:after {
  box-sizing: inherit;
}