.clear-both {
  clear: both;
}

.margin-hack-for-desktop {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.scroll-lock {
  overflow: hidden;
}
