a.hamburger-button {
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  margin: 0;
  padding: 0 10px 0 15px;
  text-decoration: none;

  div {
    width: 30px;
    height: 4px;
    background-color: $primary-color;
    margin: 5px 0;
  }

  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.hamburger-menu {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  transform: TranslateX(100%);

  &.hamburger-menu-open {
    transform: TranslateX(0);
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 .5rem 0;
    padding: 6px 15px;
    background-color: $primary-color;
    color: #ffffff;

    h2,
    h2 a {
      text-align: left;
      color: #ffffff;
      margin: 0;
    }

    .hamburger-menu-close-button {
      color: #ffffff;
      font-weight: bold;
      text-decoration: none;
    }
  }

  main {
    position: relative;
    transition: transform 0.5s;

    ul {
      width: 100%;
      list-style-type: none;

      li {
        text-align: left;

        & > a,
        & > span {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          padding: .7rem 1rem;
          text-decoration: none;

          &.hamburger-menu-logout {
            margin-top: 1rem;
          }
        }

        &.hamburger-menu-page-title {
          margin-bottom: .5rem;
        }

        // Make subcategory of selected item visible
        &.selected > ul {
          display: block;
        }
      }

      // Positioning of subcategory pages
      ul {
        display: none;
        position: absolute;
        top: 0;
        left: 100%;
      }
    }

     @include media-breakpoint-down(sm) {
        .resale-pitch-menu-container { 
            border: 1px solid $secondary-color;
            padding: 14px;
        }
        .resalePitchButton {
          margin: auto;
          padding: 14px 0 14px 0;
          width: 50%;       
       }
    }
  }    
}
