input {
  font-size: 1rem;
}

div.invoerform input {
  font-size: 1rem;
  height: auto;
}

select {
  font-size: 1rem;
}