#menu {
  background: none;
  width: 100%;

  .menu_top, .menu_bottom {
    background: none;
  }

  .menu_top {
    border: 1px solid $secondary-color;
  }

  ul, ul li {
    border: none;
  }

  h1, a.info {
    font-size: inherit;
  }

  .resale-pitch-menu-container {
    margin-top: map-get($spacers, 3);
    border: 1px solid $secondary-color;
    padding: 14px;
  }
  
  .resalePitchButton {
    margin: auto;
    width: 75%;
    padding: 14px 0 14px 0;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}