.modalOverlay {
  position: fixed;
}

.modal {
  max-width: calc(100% - 2rem);
  height: auto;
  min-height: auto;
}

#timeoutBox {
  background-color: rgba(255, 255, 255, 0.9);
}