.information_container {
  p {
    margin-bottom: .5rem;
  }

  ul {
    padding: 0 0 0 15px;
    margin: 0px 0 10px 0;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1rem;
  }
}