#lastYearsInterestForm {
  .invoerform {
    .borderwrap ~ br {
      display: none;
    }
  }

  .select-konto {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem !important; // The original rule has an !important too

    label {
      width: auto;
      float: none;
    }
  }
}